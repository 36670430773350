import React from 'react';

const LogoIcon = () => (
  <a href='https://cybersure.services'>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="220.000000pt" viewBox="0 0 843.000000 175.000000"
  preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,175.000000) scale(0.100000,-0.100000)"
  fill="#000000" stroke="none">
  <path d="M1510 815 c0 -403 3 -615 10 -615 7 0 10 212 10 615 0 403 -3 615
  -10 615 -7 0 -10 -212 -10 -615z"/>
  <path d="M633 1390 c-65 -14 -152 -52 -216 -94 -180 -119 -288 -359 -257 -570
  53 -361 362 -597 711 -541 107 17 200 55 286 118 58 43 130 137 119 155 -3 5
  -65 45 -137 87 -105 63 -133 76 -140 64 -5 -8 -9 -17 -9 -21 0 -15 -67 -72
  -105 -89 -51 -23 -161 -25 -213 -4 -50 21 -108 79 -140 140 -19 38 -26 70 -30
  131 -8 147 42 251 148 307 50 26 62 29 136 25 97 -5 159 -36 201 -99 14 -22
  32 -38 39 -35 11 4 217 149 238 167 4 4 -5 27 -22 51 -58 85 -153 154 -261
  191 -64 22 -277 32 -348 17z"/>
  <path d="M1935 1368 c-54 -47 -61 -225 -12 -292 7 -10 35 -22 61 -27 65 -12
  111 8 131 55 23 55 20 66 -20 66 -28 0 -37 -5 -44 -24 -11 -28 -44 -39 -66
  -22 -20 14 -31 108 -18 156 12 46 53 54 79 15 13 -20 24 -25 48 -23 28 3 31 6
  29 33 -1 19 -13 40 -33 58 -40 36 -116 39 -155 5z"/>
  <path d="M2586 1381 c-4 -5 14 -49 39 -97 41 -81 45 -94 45 -162 l0 -73 33 3
  32 3 5 80 c5 70 11 89 48 157 24 43 40 82 37 88 -3 5 -19 10 -36 10 -24 0 -33
  -8 -56 -49 l-28 -49 -25 46 c-21 39 -31 48 -57 50 -17 2 -34 -1 -37 -7z"/>
  <path d="M3294 1377 c-2 -7 -3 -82 -2 -167 l3 -155 60 -3 c91 -5 123 4 145 41
  25 41 25 68 -1 100 l-20 26 23 31 c21 29 21 33 8 73 -12 34 -22 44 -50 54 -49
  17 -159 17 -166 0z m134 -66 c8 -4 12 -19 10 -32 -2 -20 -10 -25 -40 -27 -37
  -3 -38 -3 -38 32 0 33 3 36 28 36 15 0 33 -4 40 -9z m10 -155 c5 -23 -14 -36
  -50 -36 -25 0 -28 4 -28 31 0 29 2 30 37 27 28 -2 39 -8 41 -22z"/>
  <path d="M4007 1383 c-4 -3 -7 -80 -7 -170 l0 -163 100 0 100 0 0 35 0 35 -65
  0 -65 0 0 35 0 34 63 3 c62 3 62 3 65 36 l3 32 -65 0 -66 0 0 30 0 30 65 0 65
  0 0 35 0 35 -93 0 c-52 0 -97 -3 -100 -7z"/>
  <path d="M4698 1384 c-5 -4 -8 -81 -8 -171 l0 -164 33 3 c31 3 32 5 37 57 l5
  54 39 -57 c36 -52 41 -56 77 -56 22 0 39 5 39 10 0 6 -19 36 -42 67 -30 41
  -38 58 -28 61 63 21 84 121 35 171 -20 21 -36 25 -102 29 -43 2 -81 1 -85 -4z
  m135 -85 c17 -28 1 -49 -38 -49 -33 0 -35 2 -35 36 0 34 1 35 31 32 18 -2 37
  -10 42 -19z"/>
  <path d="M5409 1361 c-23 -24 -29 -38 -29 -74 0 -52 12 -65 93 -101 58 -25 72
  -47 41 -64 -25 -14 -54 -2 -65 24 -7 19 -16 24 -44 24 -40 0 -43 -11 -20 -66
  16 -39 69 -68 110 -59 14 3 36 7 50 10 49 10 74 112 38 153 -10 10 -44 30 -76
  42 -48 19 -58 26 -55 44 5 33 52 34 74 2 11 -18 25 -26 45 -26 37 0 43 12 25
  53 -19 48 -51 67 -109 67 -40 0 -53 -5 -78 -29z"/>
  <path d="M6070 1260 c0 -180 16 -209 115 -212 53 -1 60 1 86 30 29 32 29 32
  29 172 l0 140 -35 0 -35 0 0 -124 c0 -125 -3 -135 -41 -149 -37 -13 -44 8 -49
  140 l-5 128 -32 3 -33 3 0 -131z"/>
  <path d="M6777 1383 c-4 -3 -7 -80 -7 -170 l0 -163 35 0 34 0 3 58 3 57 43
  -57 c39 -54 46 -58 82 -58 22 0 40 3 40 6 0 3 -21 34 -46 70 -29 40 -41 64
  -32 64 25 0 59 47 64 87 9 78 -36 111 -152 112 -33 1 -64 -2 -67 -6z m143 -84
  c16 -28 -4 -49 -46 -49 -32 0 -34 2 -34 35 0 33 2 35 35 35 24 0 37 -6 45 -21z"/>
  <path d="M7487 1383 c-4 -3 -7 -80 -7 -170 l0 -163 100 0 100 0 0 35 0 35 -65
  0 -65 0 0 35 0 34 63 3 c62 3 62 3 65 36 l3 32 -65 0 -66 0 0 30 0 30 65 0 65
  0 0 35 0 35 -93 0 c-52 0 -97 -3 -100 -7z"/>
  <path d="M8244 1095 c-10 -25 4 -45 30 -45 13 0 26 7 30 15 9 25 -5 45 -30 45
  -14 0 -27 -7 -30 -15z"/>
  <path d="M1929 611 c-23 -23 -29 -38 -29 -70 0 -48 26 -76 93 -102 51 -20 68
  -43 47 -64 -21 -20 -59 -8 -66 22 -6 21 -11 24 -43 21 -32 -3 -36 -6 -33 -28
  7 -64 47 -95 118 -92 75 3 106 32 106 98 0 50 -24 80 -81 99 -60 20 -79 38
  -61 60 20 24 59 19 70 -10 7 -19 17 -25 40 -25 39 0 46 19 25 63 -19 41 -50
  57 -111 57 -37 0 -51 -6 -75 -29z"/>
  <path d="M2617 633 c-4 -3 -7 -80 -7 -170 l0 -163 100 0 100 0 0 35 0 35 -65
  0 -65 0 0 35 0 34 63 3 c62 3 62 3 65 36 l3 32 -65 0 -66 0 0 30 0 30 65 0 65
  0 0 35 0 35 -93 0 c-52 0 -97 -3 -100 -7z"/>
  <path d="M3295 608 c-3 -18 -4 -94 -3 -168 l3 -135 33 -3 c31 -3 32 -2 32 37
  0 73 9 76 52 16 36 -51 42 -55 79 -55 21 0 39 3 39 7 0 4 -18 33 -41 66 -39
  55 -40 59 -23 72 11 8 22 15 25 15 11 0 29 51 29 81 0 69 -48 99 -154 99 l-65
  0 -6 -32z m146 -54 c18 -22 0 -48 -36 -52 -35 -4 -45 6 -38 41 4 22 11 27 33
  27 15 0 33 -7 41 -16z"/>
  <path d="M3960 627 c0 -7 23 -84 50 -170 l51 -157 33 0 c32 0 35 3 59 68 33
  91 77 236 77 257 0 12 -8 16 -32 13 -31 -3 -32 -6 -66 -107 -21 -62 -37 -98
  -42 -90 -3 8 -17 50 -30 94 -13 44 -28 86 -33 93 -13 16 -67 16 -67 -1z"/>
  <path d="M4767 634 c-4 -4 -7 -81 -7 -171 l0 -163 35 0 35 0 -2 168 -3 167
  -25 3 c-14 2 -29 0 -33 -4z"/>
  <path d="M5415 618 c-54 -47 -61 -225 -12 -292 7 -10 35 -22 61 -27 65 -12
  111 8 131 55 23 55 20 66 -20 66 -28 0 -37 -5 -44 -24 -11 -28 -44 -39 -66
  -22 -20 14 -31 108 -18 156 12 46 53 54 79 15 13 -20 24 -25 48 -23 28 3 31 6
  29 33 -1 19 -13 40 -33 58 -40 36 -116 39 -155 5z"/>
  <path d="M6085 508 c-4 -73 -5 -149 -3 -168 l3 -35 103 -3 102 -3 0 35 0 35
  -67 3 -68 3 0 30 c0 30 0 30 62 33 57 3 63 5 69 27 9 37 -2 45 -67 45 -59 0
  -59 0 -59 30 l0 30 66 0 65 0 -3 33 -3 32 -96 3 -97 3 -7 -133z"/>
  <path d="M6799 611 c-23 -23 -29 -38 -29 -70 0 -48 26 -76 93 -102 51 -20 68
  -43 47 -64 -21 -20 -59 -8 -66 22 -6 21 -11 24 -43 21 -32 -3 -36 -6 -33 -28
  7 -64 47 -95 118 -92 75 3 106 32 106 98 0 50 -24 80 -81 99 -60 20 -79 38
  -61 60 20 24 59 19 70 -10 7 -19 17 -25 40 -25 39 0 46 19 25 63 -19 41 -50
  57 -111 57 -37 0 -51 -6 -75 -29z"/>
  </g>
  </svg>
</a>
);

export default LogoIcon;
